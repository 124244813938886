<template>
  <div class="card-set">
    <AddStore @getStore="getStore" @closeAddStore="closeAddStore" :is_show="showAddStore" />
    <div class="form">
      <el-form-item label="关联门店设置：" class="set-store">
        <el-radio-group v-model="form.use_store">
          <el-radio :label="1">全部门店</el-radio>
          <el-radio :label="2">指定门店</el-radio>
        </el-radio-group>
        <el-button type="text" @click="showAddStore = !0">设置使用会员卡门店</el-button>
        <div class="store" v-if="form.use_store == 2">
          <el-tag color="#fff" :key="tag.id" v-for="tag in store" closable :disable-transitions="false" @close="removeStore(tag)">
            {{ tag.store_name }}
          </el-tag>
        </div>
      </el-form-item>
      <el-form-item label="入会条件：" class="condition">
        <el-radio-group v-model="form.condition" @change="conditionChange">
          <div class="condition-item">
            <el-radio :label="1">无门槛：进入个人中心领取并激活会员卡</el-radio>
          </div>
          <div class="condition-item">
            <el-radio :label="2">付费卡：每年付费</el-radio>
            <el-input v-model="form.condition_money" :min="0" type="number" placeholder="数值"></el-input>
            <span class="sp">元 可领取并激活会员卡</span>
          </div>
          <!-- <div class="condition-item">
            <el-radio :label="3">满足下列任意条件可领</el-radio>
            <el-radio-group v-model="form.condition_content.type" v-show="form.condition == 3" @change="arbitrarilyChange">
              <div class="arbitrarily-item">
                <el-radio :label="1">累计消费金额达到</el-radio>
                <el-input v-model="condition_content.money1" :min="0" type="number" placeholder="数值"></el-input>
                <span class="sp">元 可领取并激活会员卡</span>
              </div>
              <div class="arbitrarily-item">
                <el-radio :label="2">累计消费次数达到</el-radio>
                <el-input v-model="condition_content.num1" :min="0" type="number" placeholder="数值"></el-input>
                <span class="sp">笔 可领取并激活会员卡</span>
              </div>
              <div class="arbitrarily-item">
                <el-radio :label="3">累计消费次数达到</el-radio>
                <el-input v-model="condition_content.num2" :min="0" type="number" placeholder="数值"></el-input>
                <span class="sp" style="margin-right: 10px">笔 并且每笔最小金额大于</span>
                <el-input v-model="condition_content.money2" :min="0" type="number" placeholder="数值"></el-input>
                <span class="sp">元 可领取并激活会员卡</span>
              </div>
            </el-radio-group>
          </div> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="会员卡充值：" class="recharge">
        <div class="recharge-item" v-for="(item, index) in form.recharge" :key="index">
          <span>实际付费</span>
          <el-input v-model="item.pay" :min="0" type="number" placeholder="数值"></el-input>
          <span>元 充值金额</span>
          <el-input v-model="item.money" :min="0" type="number" placeholder="数值"></el-input>
          <span>元</span>
          <p v-show="index == 0">（如：实际付费95元，充值金额100元，则为用户实际支付95元，余额到账100元）</p>
          <i v-show="index != 0" class="el-icon-circle-close" @click="removeRecharge(index)"></i>
        </div>
        <el-button type="text" @click="addRecharge">增加充值选项</el-button>
      </el-form-item>
      <el-form-item label="有效期：" class="effective">
        <el-radio-group v-model="form.validtime">
          <div class="effective-item">
            <el-radio :label="1" :disabled="disabled">永久有效</el-radio>
          </div>
          <div class="effective-item" v-show="form.condition != 2">
            <el-radio :label="2">领卡激活时起</el-radio>
            <el-input v-model="form.vaildtime_day" :min="0" type="number" placeholder="数值"></el-input>
            <span class="sp">天内有效</span>
          </div>
          <div class="effective-item" v-show="form.condition != 2">
            <el-radio :label="3">领卡激活时起</el-radio>
            <el-date-picker v-model="effectiveTimeVal" type="daterange" range-separator="~" @change="getEffectiveTimeVal" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
        </el-radio-group>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import AddStore from '@/views/goods/components/AddStore'
export default {
  components: {
    AddStore
  },
  props: ['cardInfo'],
  data() {
    return {
      showAddStore: !1,
      store: [],
      disabled: !0,
      effectiveTimeVal: '',
      condition_content: {
        money1: '',
        money2: '',
        num1: '',
        num2: ''
      },
      form: {
        use_store: 1,
        condition: 1,
        condition_money: '',
        condition_content: {
          type: 1,
          num: '',
          money: ''
        },
        //充值金额
        recharge: [
          {
            pay: '',
            money: ''
          }
        ],
        validtime: 2, //有效期 1永久有效2领卡激活是起validtime_day天內3开始时间 结束时间
        vaildtime_day: '', //有效天数
        validtime_start: '', //开始时间
        validtime_end: '' //结束时间
      }
    }
  },
  watch: {
    cardInfo(val) {
      this.store = val.focus_store.list
      this.form = val
    }
  },
  methods: {
    //处理接口请求需要的数据
    handleData() {
      try {
        let form = this.form
        let type = form.condition_content.type
        if (form.use_store == 2 && !this.store.length) throw '请选择门店'
        if (form.condition == 1 || form.condition == 3) {
          if (form.validtime == 2 && !form.vaildtime_day) throw '请填写激活起的有效天数'
          if (form.validtime == 3 && !this.effectiveTimeVal) throw '请选择激活时间'
        }
        if (form.condition == 2 && !form.condition_money) throw '请填写年付费金额'
        if (form.condition == 3) {
          if (type == 1 && !this.condition_content.money1) throw '请填写累计消费金额'
          if (type == 2 && !this.condition_content.num1) throw '请填写累计消费次数'
          if (type == 3 && (!this.condition_content.num2 || !this.condition_content.money2)) throw '请填写累计消费次数以及每笔最小金额'
        }
        type == 1
          ? (form.condition_content.money = this.condition_content.money1)
          : type == 2
          ? (form.condition_content.num = this.condition_content.num1)
          : ((form.condition_content.num = this.condition_content.num2), (form.condition_content.money = this.condition_content.money2))
        //处理门店id
        if (form.use_store == 2) {
          let focus_store = []
          for (let i in this.store) {
            focus_store.push(this.store[i].id)
          }
          form.focus_store = focus_store.join()
        }
        for (let i in form.recharge) {
          for (let y in form.recharge[i]) {
            if (!form.recharge[i][y]) throw '请填写会员卡充值内容'
          }
        }
        return form
      } catch (e) {
        this.$message({
          message: e,
          type: 'warning'
        })
      }
    },
    //删除充值选项
    removeRecharge(index) {
      this.form.recharge.splice(index, 1)
    },
    removeStore(tag) {
      this.store.splice(this.store.indexOf(tag), 1)
    },
    getStore(val) {
      this.showAddStore = !1
      this.store = val
    },
    closeAddStore() {
      this.showAddStore = !1
    },
    conditionChange(val) {
      this.form.condition_money = ''
      if (val == 2) {
        this.form.validtime = 1
        this.disabled = !1
      } else {
        this.disabled = !0
        this.form.validtime = 2
      }
    },
    //天机充值选项
    addRecharge() {
      this.form.recharge.push({
        pay: '',
        money: ''
      })
    },
    arbitrarilyChange(val) {
      for (let i in this.condition_content) this.condition_content[i] = ''
    },
    getEffectiveTimeVal(val) {
      this.form.validtime_start = val[0].getTime() / 1000
      this.form.validtime_end = val[1].getTime() / 1000
    }
  }
}
</script>

<style lang="less" scoped>
.card-set {
  padding: 20px 0 0 30px;
  .el-form-item {
    // max-width: 700px;
    .sp {
      font-size: 14px;
    }
  }
  .set-store {
    .el-button {
      margin-left: 20px;
    }
    .store {
      max-width: 500px;
      .el-tag {
        margin-right: 10px;
      }
    }
  }
  .condition .condition-item {
    margin-bottom: 20px;
    &:nth-child(2) {
      display: flex;
      align-items: center;
      .el-input {
        width: 120px;
        margin-right: 10px;
      }
    }
    &:nth-child(3) {
      display: flex;
      flex-direction: column;
      .arbitrarily-item {
        padding-left: 22px;
        margin-bottom: 20px;
        .el-input {
          width: 120px;
          margin-right: 10px;
        }
      }
    }
  }
  .recharge {
    .recharge-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span {
        margin-right: 10px;
      }
      p {
        color: #909399;
      }
      .el-input {
        width: 180px;
        margin-right: 10px;
      }
      i {
        font-size: 22px;
      }
    }
  }
  .effective .effective-item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    .el-input {
      width: 170px;
      margin-right: 10px;
    }
  }
}
</style>
