<template>
	<div class="card-interface">
		<el-dialog :title="operate_type ? '添加会员等级' : '编辑会员等级'" :visible.sync="showSetGraed" width="600px">
			<el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
				<el-form-item label="会员等级名称:" prop="title">
					<el-input v-model="addForm.title"></el-input>
				</el-form-item>
				<el-form-item label="成长值≧:" prop="grow_value">
					<el-input style="width: 150px;margin-right: 15px;" v-model="addForm.grow_value"></el-input>
					<i class="el-icon-warning-outline"></i>
					成长值不能低于上个等级成长值
				</el-form-item>
				<el-form-item label="全部商品包邮:">
					<el-switch v-model="addForm.is_all" active-value="1" inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="商品折扣:" prop="goods_discount">
					<el-input style="width: 300px;margin-right: 15px;" v-model="addForm.goods_discount"></el-input>
					折
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showSetGraed = !1">取 消</el-button>
				<el-button type="primary" @click="comfirmaddInterface">确 定</el-button>
			</span>
		</el-dialog>
		<div class="interface">
			<span class="sp">会员等级：</span>
			<div class="interface-list">
				<div class="list">
					<div class="item" v-for="(item, index) in list" :key="index" @mouseover="list[index].is_set = !0"
						@mouseout="list[index].is_set = !1">
						<div class="info">
							<p>{{ item.title }}</p>
							<p>成长值≧{{ item.grow_value }}</p>
							<p v-if="item.is_all == '1'">全部商品包邮</p>
							<p>商品{{ item.goods_discount }}折</p>
						</div>
						<div class="val">
							<div>{{ item.grow_value }}</div>
							<div></div>
						</div>
						<div v-show="item.is_set" class="operate" @click.stop="">
							<el-button type="text" size="small" @click="editCurrentGrade(item,index)">编辑</el-button>
							<el-button type="text" size="small" @click="removeCurrentGrade(item.id,index)">删除
							</el-button>
						</div>
					</div>
				</div>
				<el-button type="primary" @click="showAddGrade">添加会员等级</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			var validateGrow = (rule, value, callback) => {
				if (this.addForm.goods_discount > this.$store.state.utils.member_discount) {
					callback(new Error('商品折扣不能大于会员激活享受折扣'));
				} else if (this.preIndex && this.addForm.goods_discount >= this.list[this.operate_type ? Number(this
						.list.length - 1) : this.preIndex - 1].goods_discount) {
					callback(new Error('商品折扣不能大于上一个等级的商品折扣'));
				} else {
					callback();
				}
			}
			return {
				preIndex: 0,
				showSetGraed: !1,
				list: [],
				operate_type: 1,
				addForm: {
					title: '',
					is_all: 0,
					goods_discount: '',
					grow_value: '',
				},
				rules: {
					title: [{
						required: true,
						message: '请填写会员等级名称',
						trigger: 'blur'
					}],
					grow_value: [{
						required: true,
						message: '请填写成长值',
						trigger: 'blur'
					}],
					goods_discount: [{
						required: true,
						message: '请填写商品折扣',
						trigger: 'blur'
					}, {
						validator: validateGrow,
						trigger: 'blur'
					}]
				},
			};
		},
		created() {
			this.getGradeList();
		},
		methods: {
			getGradeList() {
				this.$axios.post(this.$api.user.CardLevel).then(res => {
					if (res.code == 0) {
						let list = res.result.list;
						if (list.length) {
							for (let i in list) {
								list[i].is_set = !1;
								list[i].is_all = String(list[i].is_all);
							}
							if (list[0].grow_value == '') list[0].grow_value = '0';
						}
						this.list = list;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			showAddGrade() {
				if (this.list.length == 10) return this.$message.warning('最多只能添加10个会员等级');
				this.showSetGraed = !0;
				this.operate_type = 1;
				this.$refs.form.resetFields();
				this.addForm = {
					title: '',
					is_all: 0,
					goods_discount: '',
					integral: '',
					grow_value: '',
				}
			},
			//删除当前会员等级
			removeCurrentGrade(id, index) {
				this.$confirm('确认要删除当前会员等级吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					this.$axios
						.post(this.$api.user.CardLeveldel, {
							id: id
						})
						.then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功')
								this.list.splice(index, 1);
							} else {
								this.$message.error(res.msg)
							}
						});
				});
			},
			//编辑当前会员等级
			editCurrentGrade(row, index) {
				this.addForm = JSON.parse(JSON.stringify(row));
				this.preIndex = index;
				this.operate_type = 0;
				this.showSetGraed = !0;
			},
			comfirmaddInterface() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						let addForm = this.addForm;
						let data = {
							title: addForm.title,
							is_all: addForm.is_all,
							goods_discount: addForm.goods_discount,
							grow_value: addForm.grow_value,
							is_discount: addForm.goods_discount ? 1 : 0
						};
						if (addForm.id) data.id = addForm.id;
						let url = addForm.id ? this.$api.user.CardLeveledit : this.$api.user.CardLeveladd;
						this.$axios.post(url, data).then(res => {
							if (res.code == 0) {
								this.showSetGraed = !1;
								this.$message.success(addForm.id ? '编辑成功' : '添加成功')
								this.getGradeList();
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.card-interface {
		padding: 20px 0 0 30px;

		.sp {
			line-height: 38px;
			width: 140px;
			font-size: 14px;
			color: #606266;
			text-align: center;
		}

		.interface {
			display: flex;

			.interface-list {
				.list {
					margin-top: 40px;
					margin-bottom: 40px;
					display: flex;
					flex-wrap: wrap;

					.item {
						height: 125px;
						width: 242px;
						position: relative;
						display: flex;
						margin-bottom: 50px;
						border-radius: 6px;
						margin-right: 10px;

						.operate {
							position: absolute;
							right: 10px;
							top: 0;
						}

						.info {
							font-size: 14px;
							padding-left: 20px;

							p {
								margin-top: 10px;

								&:nth-child(1) {
									font-size: 18px;
								}
							}
						}

						.val {
							position: absolute;
							left: 0;
							top: -33px;
							display: flex;
							flex-direction: column;

							div:first-child {
								position: relative;
								left: -5px;
							}

							div:last-child {
								height: 15px;
								width: 1px;
								background: rgb(63, 60, 60);
								text-align: center;
							}
						}

						&:nth-child(1) {
							background: linear-gradient(0deg, #f8fbfc, #e9eff1);
						}

						&:nth-child(2) {
							background: linear-gradient(0deg, #f5f6f9, #e7eaed);
						}

						&:nth-child(3) {
							background: linear-gradient(0deg, #f2f4fb, #e6e8f0);
						}

						&:nth-child(4) {
							background: linear-gradient(0deg, #fcf7ea, #f6ecdb);
						}

						&:nth-child(5) {
							background: linear-gradient(0deg, #faf0ea, #f4e7e2);
						}

						&:nth-child(6) {
							background: linear-gradient(0deg, #f4eff8, #decfea);
						}

						&:nth-child(7) {
							background: linear-gradient(0deg, #f1f2fc, #ceceeb);
						}

						&:nth-child(8) {
							background: linear-gradient(0deg, #e7fff9, #c5f2e6);
						}

						&:nth-child(9) {
							background: linear-gradient(0deg, #e1f2f9, #c1ddec);
						}

						&:nth-child(10) {
							background: linear-gradient(0deg, #e6eefa, #c7d7ef);
						}
					}
				}
			}
		}
	}
</style>
