<template>
	<el-main>
		<el-form label-width="140px">
			<el-tabs v-model="activeName">
				<el-tab-pane label="会员卡界面" name="first">
					<CardInterface ref="cardInterface" :cardInfo="cardInfo"></CardInterface>
				</el-tab-pane>
				<el-tab-pane label="会员卡设置" name="second">
					<CardSet ref="cardSet" :cardInfo="cardInfo"></CardSet>
				</el-tab-pane>
				<el-tab-pane label="成长值" name="third">
					<GrowthValue ref="growthValue" :growValue="cardInfo.grow_value"></GrowthValue>
				</el-tab-pane>
				<el-tab-pane label="会员等级" name="fourth">
					<CardGrade></CardGrade>
				</el-tab-pane>
			</el-tabs>
		</el-form>
		<Preservation v-if="activeName !='fourth'" @preservation="submit"></Preservation>
	</el-main>
</template>

<script>
	import CardGrade from './components/CardGrade';
	import CardInterface from './components/CardInterface';
	import CardSet from './components/CardSet';
	import GrowthValue from './components/GrowthValue';
	import Preservation from '@/components/preservation';
	export default {
		components: {
			CardGrade,
			CardInterface,
			CardSet,
			GrowthValue,
			Preservation,
		},
		data() {
			return {
				activeName: 'first',
				cardInfo: {},
			};
		},
		created() {
			this.getCardInfo();
		},
		methods: {
			//获取会员卡信息
			getCardInfo() {
				this.$axios.post(this.$api.user.getCardInfo).then(res => {
					if (res.code == 200) {
						this.$store.state.utils.member_discount = res.result.act_reward.goods_idscount_num;
						this.cardInfo = res.result;
					}
				});
			},
			submit() {
				let activeName = this.activeName;
				let memberData = {};
				let url = this.cardInfo.id ? this.$api.user.editCard : this.$api.user.addCard;
				if (activeName != 'fourth') {
					let result1 = this.$refs.cardInterface.handleData();
					let result2 = this.$refs.cardSet.handleData();
					if (result1 && result2) {
						memberData = JSON.parse(JSON.stringify(Object.assign(memberData, result1, result2)));
						let grow_value = this.$refs.growthValue.grow_value;
						memberData.grow_value = grow_value;
						this.$axios.post(url, memberData).then(res => {
							if (res.code == 200 || res.code == 0) {
								this.$message.success('保存成功');
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.el-main {
		background: #fff;
		padding: 40px 60px;
		height: 100%;
	}
</style>
